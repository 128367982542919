@import url(../../bower_components/bootstrap/dist/css/bootstrap-grid.min.css);
@import url(../../bower_components/owl.carousel/dist/assets/owl.carousel.min.css);
@import url(../../bower_components/slick-carousel/slick/slick.css);
@import url(../../bower_components/fancybox/dist/jquery.fancybox.min.css);
* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-size: 10px; }
  @media (max-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (max-width: 576px) {
    html {
      font-size: 8px; } }

body {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 300;
  color: black;
  min-width: 320px; }

h2 {
  margin-top: 2rem;
  margin-bottom: 0;
  font-size: 3.5rem;
  font-weight: bold;
  text-align: left; }

h3 {
  font-weight: bold;
  font-size: 2.4rem; }

h5 {
  font-size: 2rem;
  font-weight: bold; }

a {
  color: inherit; }
  a:hover, a:focus {
    color: inherit;
    text-decoration: underline;
    outline: 0; }

p {
  margin-bottom: 1rem; }

ol, ul {
  list-style-position: inside; }

ol {
  font-size: 1.1rem;
  color: #515151; }

img {
  height: auto;
  max-width: 100%; }

input:focus, textarea:focus, button:focus {
  outline: 0; }

iframe {
  display: block;
  margin: 0 auto 0rem; }

table {
  width: 100%; }
  table td {
    min-width: 25rem;
    font-weight: 500;
    line-height: normal;
    font-size: 1.2rem; }
    @media (max-width: 578px) {
      table td {
        min-width: 100%; } }

label {
  cursor: pointer; }

input[type=range] {
  position: relative;
  display: block;
  margin: 0 auto 1rem;
  padding: 0;
  width: 90%;
  height: 4px;
  background: transparent;
  outline: none;
  border: none;
  opacity: 1;
  z-index: 2;
  appearance: none;
  transition: all 0.2s; }
  input[type=range]::-webkit-slider-thumb {
    appearance: none;
    width: 21px;
    height: 21px;
    margin-left: -5px;
    background: #eb8c00;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 0 10px 1px #d0d0d0;
    cursor: pointer; }
  input[type=range]::-moz-range-thumb {
    width: 21px;
    height: 21px;
    background: #eb8c00;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 0 10px 1px #d0d0d0;
    cursor: pointer; }
  input[type=range]:hover {
    opacity: 1; }
  input[type=range]:focus {
    outline: none; }

input[type=file] {
  display: none; }

.slick-vertical .slick-slide {
  outline: none;
  border: none; }

/* Bootstrap */
.container {
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }
  .container--abc {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding-top: 3rem; }

.row:not(.no-gutters) {
  margin-left: -1rem;
  margin-right: -1rem; }
  @media screen and (min-width: 992px) {
    .row:not(.no-gutters)-five > [class*='col-'] {
      width: 20%; } }
  @media screen and (max-width: 992px) {
    .row:not(.no-gutters)-five > [class*='col-'] {
      width: 33.33%; } }
  @media screen and (max-width: 768px) {
    .row:not(.no-gutters)-five > [class*='col-'] {
      float: left;
      width: 50%; } }

[class*='col-'] {
  padding-left: 1rem;
  padding-right: 1rem; }

.media-object {
  max-width: 1000px !important; }

.lead {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 3rem; }

.form-group {
  margin-bottom: 2rem; }

.tabs .nav {
  margin: 0 auto 3.5rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase; }

.tabs > li > a:hover,
.tabs > li > a:focus {
  text-decoration: none;
  background-color: #e5e5e5;
  border: none; }

.tabs .nav-tabs {
  border-bottom: none; }
  .tabs .nav-tabs > li > a {
    border: none;
    border-radius: 4px; }
  .tabs .nav-tabs > li.active > a,
  .tabs .nav-tabs > li.active > a:hover,
  .tabs .nav-tabs > li.active > a:focus {
    border-radius: 5px; }

.item-tabs {
  display: inline-block !important;
  float: none !important; }

.active .nav-tabs__link {
  background: #eb8c00 !important;
  color: #fff !important; }

.nav-tabs__link:hover {
  color: #eb8c00 !important; }

.nav-tabs__link:focus {
  color: #fff !important; }

.multiple-items {
  height: 100% !important; }

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
  color: none;
    color-outline: 0;
    color-background: none; }

.slick-next, .slick-prev {
  display: block;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  outline: 0;
  border: none;
  color: transparent;
  cursor: pointer;
  padding: 0;
  height: 20px;
  left: 15px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  background-color: transparent; }

.slick-next::before, .slick-prev::before {
  content: none; }

.slick-next {
  background-image: url("/img/right.svg");
  bottom: -35px; }

.slick-prev {
  background-image: url("/img/left.svg");
  top: -35px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F2F6F7; }

.table-striped tbody tr td {
  border: none; }

.header.is-clone {
  position: fixed;
  top: -10rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s; }

.is-clone {
  padding: 1.1rem 0; }

.header.is-fixed {
  top: 0 !important; }

.block-fixed {
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 400; }

.is-fixed {
  position: fixed;
  width: 100%;
  z-index: 10000;
  -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  top: 0 !important; }

.fancybox-content {
  padding: 0; }

.carousel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  padding: 0 5.5rem;
  overflow: hidden; }
  .carousel--p0 {
    padding: 0; }
  .carousel__content {
    position: relative;
    overflow: hidden;
    z-index: 10; }
  .carousel__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 0; }
  .carousel__nav.disabled {
    display: none !important; }
  .carousel__nav-item {
    margin: 0;
    color: #333;
    background: 0 0;
    line-height: 1;
    cursor: pointer;
    border: none; }
  .carousel__nav-item--prev::before {
    content: url(/img/left.svg); }
  .carousel__nav-item--next::before {
    content: url(/img/right.svg); }
  .carousel__nav-item:hover {
    color: #000;
    background: 0 0; }
  .carousel__dots {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: auto;
    z-index: 5;
    margin-top: 2rem; }
  .carousel__dots-item {
    width: 1.2rem;
    height: 1.2rem;
    margin: .5rem .75rem;
    background: #949699;
    border: 1px solid #515255;
    border-radius: 3rem;
    cursor: pointer;
    transition: transform 0.3s; }
  .carousel__dots-item.active, .carousel__dots-item:hover {
    background: #F4B000;
    border: 1px solid #515255;
    transform: scale(1.25); }

.carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.section {
  padding: 6rem 0 10rem;
  color: #2E3033;
  background-color: #ffffff; }
  .section--first {
    padding-bottom: 4rem; }
  .section--second {
    padding-bottom: 6rem; }
  .section-bg {
    position: relative;
    background-size: cover;
    background-attachment: fixed; }
    .section-bg.\--dark {
      background-color: #F2F3F5;
      color: #ffffff; }
    .section-bg.\--lightDark {
      background-color: #FAFAFA; }
  .section__title {
    font-weight: bold;
    line-height: normal;
    font-size: 2.8rem;
    margin-bottom: 7rem;
    color: #0070B1; }
    .section__title--center {
      text-align: center; }
  @media (max-width: 768px) {
    .section--after-promo {
      padding-top: 12rem; } }
  .section__desc {
    font-weight: normal;
    line-height: 2.3rem;
    font-size: 1.6rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 5rem; }

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: normal;
  line-height: 1.8rem;
  font-size: 1.4rem;
  text-align: center;
  padding: 0.5rem 1.5rem;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 16px rgba(0, 0, 0, 0.16);
  text-decoration: none;
  border-radius: 2px;
  transition: all 0.2s;
  border: none;
  cursor: pointer; }
  .btn:focus {
    text-decoration: none;
    color: #ffffff; }
  .btn:active {
    background-color: #016098;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.08); }
  .btn:hover {
    color: #ffffff;
    background-color: #016098;
    text-decoration: none; }
  .btn--lg {
    min-width: 13rem;
    text-transform: uppercase;
    padding: 1.6rem 2rem;
    letter-spacing: 0.02em;
    line-height: normal;
    font-weight: bold; }
  .btn--tm1 {
    border: dotted 2px #fff;
    background-color: transparent;
    color: #fff;
    box-shadow: none; }
    .btn--tm1:hover {
      border-color: #016098;
      background-color: transparent;
      color: #fff; }
    .btn--tm1:active {
      background-color: #F2F3F5;
      color: #fff;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.08); }
    .btn--tm1:focus {
      color: #fff; }
  .btn--tm2 {
    border: solid 1px #fff;
    background-color: transparent;
    color: #fff;
    box-shadow: none;
    font-weight: 300; }
    .btn--tm2:hover {
      border-color: #016098;
      background-color: transparent;
      color: #fff; }
    .btn--tm2:active {
      background-color: #F2F3F5;
      color: #fff;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.08); }
    .btn--tm2:focus {
      color: #fff; }
  .btn--submit {
    width: 100%; }
  .btn--white {
    background-color: #fff;
    color: #fff; }
    .btn--white:hover, .btn--white:focus {
      background-color: #F2F3F5;
      color: #016098;
      border-color: #E1E2E6; }
    .btn--white:active {
      background-color: #E1E2E6;
      border-color: #BCBFC4;
      color: #016098;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.08); }

.wrap-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem; }

.list li {
  display: flex;
  align-items: center; }

.list--sm {
  font-weight: normal;
  line-height: 1.3rem;
  font-size: 1.2rem; }

.list--md {
  font-weight: normal;
  line-height: 2.5rem;
  font-size: 1.6rem; }

.list--checked {
  list-style: none;
  position: relative;
  padding-left: 3.5rem; }
  .list--checked li::before {
    content: '';
    position: absolute;
    left: 0;
    background-image: url("/img/list.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 2.5rem;
    height: 2rem; }

.list--circle {
  list-style: none;
  position: relative;
  padding-left: 2.5rem; }
  .list--circle li::before {
    content: '';
    position: absolute;
    left: 0;
    background-image: url("/img/list-circle.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 1.5rem;
    height: 1.5rem; }

.link {
  font-weight: normal;
  line-height: 2.5rem;
  font-size: 1.6rem;
  color: #2E3033;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #2E3033;
  text-decoration: none; }
  .link:hover, .link:focus, .link:active {
    text-decoration: none; }
  .link:hover {
    color: #2E3033; }
  .link--tm1 {
    color: #2E3033;
    border-bottom: 1px solid #E1E2E6; }
    .link--tm1:hover, .link--tm1:focus, .link--tm1:active {
      color: #515255; }
  .link--tm2 {
    color: #016098;
    border: none; }
    .link--tm2:hover {
      text-decoration: underline;
      color: #016098; }
  .link--tm3 {
    border-color: #016098; }

.c-message__item {
  padding: 4rem 8rem 4rem 4rem;
  display: flex;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  background-color: #ffffff;
  position: relative; }
  .c-message__item--close {
    position: absolute;
    right: 0;
    top: 0;
    width: 3.2rem;
    height: 3.2rem;
    opacity: 0.3;
    cursor: pointer; }
    .c-message__item--close:hover {
      opacity: 1; }
    .c-message__item--close:before, .c-message__item--close:after {
      content: ' ';
      position: absolute;
      left: 1rem;
      top: 1rem;
      height: 2.3rem;
      width: .2rem;
      background-color: #FF3D33; }
    .c-message__item--close:before {
      transform: rotate(45deg); }
    .c-message__item--close:after {
      transform: rotate(-45deg); }

.c-message__title {
  font-weight: normal;
  line-height: 2.5rem;
  font-size: 1.6rem;
  color: #515255; }

.c-message__text {
  margin-top: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  font-size: 1.2rem;
  color: #949699; }

.c-message__msg {
  margin-top: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  font-size: 1.2rem;
  text-decoration-line: underline;
  color: #109756; }

.c-message__wrap-img {
  margin-right: 3.5rem;
  max-width: 12rem; }

.c-message__img {
  min-width: 10rem; }

.g-link-abc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.g-gray1 {
  color: #515255; }

.g-gray2 {
  color: #626366; }

.g-gray3 {
  color: #949699; }

.g-gray4 {
  color: #BCBFC4; }

.g-gray5 {
  color: #E1E2E6; }

.g-gray6 {
  color: #F2F3F5; }

.g-gray7 {
  color: #FAFAFA; }

.g-red1 {
  color: #FF3D33; }

.g-white {
  color: #ffffff; }

.g-blue {
  color: #016098; }

.g-margin-0 {
  margin: 0 !important; }

.g-margin-7 {
  margin: 7rem !important; }

.g-hr-line {
  border-top: 1px solid #fff;
  margin: 5rem 0; }

.c-form {
  max-width: 38rem;
  width: 100%;
  color: #FAFAFA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  text-align: left; }
  .c-form__wrap-img {
    display: flex;
    justify-content: center;
    align-items: center; }
  .c-form.\--dark {
    background-color: #515255; }
  .c-form.\--gray {
    background-color: #F2F3F5;
    color: #222222; }
  .c-form.\--white {
    background-color: #fff;
    color: #222222; }
  .c-form.\--black {
    background-color: #2E3033;
    color: #F9F9F9;
    opacity: 0.9;
    border: 1px solid #515255; }
  .c-form__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4rem; }
  .c-form__title {
    font-weight: normal;
    line-height: 3.1rem;
    font-size: 2.2rem;
    text-align: center;
    color: #016098; }
  .c-form__subtitle {
    color: #FAFAFA;
    font-weight: bold;
    line-height: 3.2rem;
    font-size: 2.3rem; }
    .c-form__subtitle.\--black {
      color: #2E3033; }
  .c-form__descr {
    margin-top: 3rem;
    line-height: 1.9rem;
    font-size: 1.5rem;
    text-align: center;
    color: #949699; }
  .c-form__conf {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    line-height: normal;
    font-size: 1rem;
    color: #BCBFC4; }
    .c-form__conf-img {
      margin-right: 1rem; }
  .c-form__item {
    margin-top: 3rem; }
  .c-form__label {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    line-height: 2.5rem;
    font-size: 1.6rem;
    color: #2E3033;
    position: relative; }
    .c-form__label-title {
      font-size: 1.2rem;
      line-height: 1.5;
      padding-left: 2rem; }
    .c-form__label.\--gray {
      color: #FAFAFA; }
    .c-form__label.\--white {
      color: #ffffff; }
  .c-form__input {
    padding: 1rem 2rem;
    margin-top: .1rem;
    border: 1px solid #e1e2e6;
    background-color: #f2f3f5;
    border-radius: 1px;
    font-size: 1.5rem;
    font-weight: 300; }
    .c-form__input:hover {
      border-color: #BCBFC4; }
    .c-form__input:focus {
      background-color: #ffffff; }
    .c-form__input::placeholder {
      color: #949699; }
    .c-form__input.\--white {
      background-color: #ffffff; }
    .c-form__input.\--gray {
      background-color: #949699;
      color: #E1E2E6;
      border-color: #949699; }
      .c-form__input.\--gray:hover {
        border-color: #BCBFC4; }
      .c-form__input.\--gray::placeholder {
        color: #E1E2E6; }
    .c-form__input.\--error {
      border-color: #ff9999;
      background-color: #ffffff; }
    .c-form__input-error {
      position: absolute;
      bottom: -2rem;
      right: 0;
      font-weight: normal;
      line-height: 1.5rem;
      font-size: 1.2rem;
      color: #ff9999; }
  .c-form__bottom {
    display: flex;
    justify-content: center;
    margin-top: 5rem; }
  .c-form__btn {
    background: #016098; }

.header {
  display: flex;
  align-items: center;
  padding: 2.5rem 0; }
  .header-bg {
    position: relative; }
    .header-bg::before {
      content: '';
      position: absolute;
      background-image: url("/img/yellow-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      top: 0;
      width: 100%;
      height: 11rem; }
  .header--small {
    min-height: 5rem; }
  .header__menu.\--mt {
    margin-top: 2.5rem; }
  .header__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none; }
  .header__contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; }
    .header__contact--clone {
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .header__phone {
    font-weight: 500;
    line-height: normal;
    font-size: 1.8rem;
    text-decoration: none; }
    .header__phone.\--xl {
      font-weight: normal;
      line-height: 3.2rem;
      font-size: 2.3rem; }
    .header__phone:hover, .header__phone:focus, .header__phone:active {
      text-decoration: none;
      color: #FAFAFA; }
    .header__phone.\--dark {
      color: #515255; }
      .header__phone.\--dark:hover, .header__phone.\--dark:focus, .header__phone.\--dark:active {
        text-decoration: none;
        color: #626366; }
  .header__wrap-logo.\--small {
    max-width: 5rem; }
  .header__logo.\--small {
    max-width: 5rem; }

.header-mobile {
  padding: 2rem;
  box-shadow: 0 0 20px 0 #BCBFC4;
  background-color: #ffffff; }
  .header-mobile__actions {
    display: flex;
    align-items: center; }
  .header-mobile__logo {
    padding-bottom: 0;
    margin-top: 0;
    flex-grow: 1; }
    .header-mobile__logo-img {
      margin: 0;
      padding-left: 15px;
      max-width: 6rem; }
  .header-mobile__phone {
    padding-right: 20px; }
  .header-mobile__inner-phone {
    margin-right: 3rem;
    position: relative; }
  .header-mobile__phone-sm {
    display: inline-block;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 1rem;
    vertical-align: middle;
    letter-spacing: .04em;
    opacity: 0; }
  .header-mobile__link-img {
    width: 2.2rem; }
  .header-mobile__list {
    list-style-type: none;
    margin: 2rem 0 0; }
    .header-mobile__list-item:not(:first-child) {
      margin-top: 1rem; }
  .header-mobile__link {
    text-decoration: none;
    color: #2E3033; }
    .header-mobile__link:hover, .header-mobile__link:active, .header-mobile__link:focus {
      text-decoration: none;
      color: #626366; }
  .header-mobile__burger {
    cursor: pointer; }

.header__contact-text {
  font-weight: 500;
  line-height: 1.4rem;
  font-size: 1.1rem;
  text-align: center;
  color: #BCBFC4;
  margin-bottom: 1rem; }

.header--margin {
  margin-bottom: 4rem; }

.header__btn {
  margin-top: 1rem; }

.header__phone {
  font-weight: 300;
  line-height: normal;
  font-size: 2.4rem;
  text-decoration: none; }

.section-promo__wrap-form {
  display: flex;
  justify-content: center;
  position: relative; }
  .section-promo__wrap-form--m-0 {
    margin: 0; }
  @media (min-width: 992px) {
    .section-promo__wrap-form {
      justify-content: flex-end;
      margin-top: 0; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .section-promo__wrap-form {
      margin-bottom: 5rem; }
      .section-promo__wrap-form--m-0 {
        margin: 0; } }

.c-main-promo__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.c-main-promo__title {
  font-weight: 300;
  line-height: 1.9rem;
  font-size: 1.4rem;
  text-align: center; }

.c-main-promo__wrap-img {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 11rem; }

.c-main-promo__img {
  max-width: 10rem; }

.c-main__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem; }

.c-hidden__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 7rem;
  padding: 3rem 0 0 0;
  text-align: center;
  cursor: pointer; }
  .c-hidden__btn::before {
    content: '';
    position: absolute;
    top: -1.5rem;
    width: 2.7rem;
    height: 2.7rem;
    background-color: transparent;
    border-top: none;
    border-bottom: 0.7rem solid #949699;
    border-left: 0.7rem solid #949699;
    border-right: none;
    transform: rotate(-45deg);
    transition: all 0.4s; }
  .c-hidden__btn--active::before {
    transition: all 0.4s;
    transform: rotate(135deg); }

.wbooster {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center; }
  .wbooster__logo {
    width: 55px; }
  .wbooster__text {
    margin: 0 0 0 .5rem;
    border-left: 1px solid #949699;
    padding-left: .5rem;
    font-size: 1.1rem;
    color: #ffffff; }
  .wbooster__link {
    text-decoration: none; }
    .wbooster__link:hover, .wbooster__link:action, .wbooster__link:focus {
      text-decoration: none; }

.section-gradient {
  color: #F2F9FF;
  border-top: 2.5rem solid #fff; }
  .section-gradient__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 34rem;
    margin-bottom: 7.5rem; }
  .section-gradient__title {
    font-weight: 300;
    line-height: normal;
    font-size: 3.6rem; }
  .section-gradient__text {
    font-weight: normal;
    line-height: 2.3rem;
    font-size: 1.6rem; }
  .section-gradient__wrap-img {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #fff;
    padding: 0 2.5rem;
    margin-top: -6rem; }
  .section-gradient__img-text {
    font-weight: normal;
    line-height: 2.1rem;
    font-size: 1.2rem;
    text-align: center;
    color: black;
    padding: 2.5rem 0; }

.c-about {
  display: flex;
  justify-content: space-between; }
  .c-about__wrap-img {
    margin-right: 7rem;
    width: 100%;
    max-width: 40rem; }
  .c-about__img {
    width: 100%;
    max-width: 40rem; }
  .c-about__text {
    font-weight: normal;
    line-height: 2.5rem;
    font-size: 1.4rem;
    color: #666; }

.section-conf__content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
  padding-top: 3rem; }

.section-conf__headline {
  font-weight: 900;
  line-height: normal;
  font-size: 4rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #626366; }

.section-conf__title {
  font-weight: normal;
  line-height: 2.5rem;
  font-size: 1.6rem;
  margin: 3rem 0 2rem 0;
  color: #646566; }

.section-conf__text {
  font-weight: normal;
  line-height: 2.5rem;
  font-size: 1.8rem; }

.c-interesting {
  display: flex;
  justify-content: space-between;
  margin-top: -3rem; }
  @media (max-width: 768px) {
    .c-interesting {
      align-items: center; } }
  .c-interesting__inner {
    display: flex;
    flex-direction: column; }
  .c-interesting__label {
    display: flex;
    align-items: center;
    font-weight: normal;
    line-height: 2.3rem;
    font-size: 1.6rem;
    padding-left: 3.7rem;
    color: #626366;
    position: relative; }
    .c-interesting__label:hover {
      text-decoration: underline; }
    .c-interesting__label::before {
      content: '';
      position: absolute;
      left: 0;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 1px solid #243962;
      background: #fafafa;
      box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.3); }
  .c-interesting [type=checkbox]:not(:checked), .c-interesting [type=checkbox]:checked {
    position: absolute;
    left: -9999px; }
  .c-interesting [type=checkbox]:checked + label:after, .c-interesting [type=checkbox]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #444;
    border-radius: 2px;
    position: absolute;
    left: 5px;
    transition: all .2s ease; }
  .c-interesting [type=checkbox]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  .c-interesting__form-group {
    margin-top: 3rem; }

.c-tab-link__item {
  max-width: 16rem; }

.c-tab-link__wrap-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  border-radius: 4px;
  border: 1px solid #F2F3F5; }
  .c-tab-link__wrap-img:hover {
    border: 2px solid #EB8C00; }
  .c-tab-link__wrap-img:active {
    border: 2px solid #EB8C00; }
  .c-tab-link__wrap-img:hover ~ .c-tab-link__title {
    color: #EB8C00; }

.c-tab-link__title {
  font-weight: 500;
  line-height: normal;
  font-size: 1.8rem;
  margin-top: 2.5rem; }

.c-tab-link__text {
  font-weight: normal;
  line-height: normal;
  font-size: 1.2rem;
  margin-top: 1rem; }

.c-advanced {
  margin-top: 10rem; }
  .c-advanced__item {
    display: flex; }
  .c-advanced__wrap-img {
    margin-right: 5rem; }
  .c-advanced__list {
    margin-top: 5rem;
    font-weight: normal;
    line-height: 2.3rem;
    font-size: 1.6rem;
    color: #16293A; }
  .c-advanced__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 55rem; }

.c-table__item {
  border-top: 1px solid rgba(36, 57, 98, 0.3);
  padding: 2rem 0; }

.c-table__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem; }

.c-table__brand {
  font-weight: bold;
  line-height: normal;
  color: #626366; }

.c-table__title {
  max-width: 33rem;
  font-weight: normal;
  line-height: normal;
  color: #626366; }

.c-table__article {
  font-weight: normal;
  line-height: normal;
  color: #626366; }

.c-table__ostatok {
  color: #68A0E6; }

.c-table__wrap-btn {
  display: flex;
  justify-content: center;
  align-items: center; }

.c-table__bottom-text {
  text-align: center;
  font-weight: 300;
  line-height: normal;
  font-size: 1.4rem;
  color: #646566;
  margin-top: 2rem; }

.c-doc__item {
  height: 100%; }

.c-doc__wrap-img {
  display: flex;
  justify-content: center;
  align-items: center; }

.c-doc__img {
  width: 100%;
  height: 100%; }

.c-brands {
  margin-top: -5rem; }
  .c-brands__item {
    margin-top: 3rem; }
  .c-brands__wrap-img {
    display: flex;
    justify-content: center;
    align-items: center; }

.c-product__item:not(:first-of-type) {
  margin-top: 5rem;
  border-top: 1px solid #999;
  padding-top: 5rem; }

@media (max-width: 768px) {
  .c-product__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

.c-product__wrap-img {
  display: flex;
  justify-content: center;
  align-items: center; }

@media (max-width: 768px) {
  .c-product__img {
    max-width: 25rem; } }

.c-product__top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.c-product__name {
  font-weight: bold;
  line-height: 2.6rem;
  font-size: 2rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #333333; }

.c-product__subname {
  font-weight: normal;
  line-height: 1.8rem;
  font-size: 1.2rem;
  color: #949699; }

.c-product__download {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  line-height: 1.8rem;
  font-size: 1.2rem;
  text-decoration-line: underline;
  color: #0070B1;
  position: relative; }
  .c-product__download::before {
    content: '';
    position: absolute;
    background-image: url("/img/pdf.svg");
    background-repeat: no-repeat;
    left: -45px;
    width: 100%;
    height: 40px; }

.c-product__text {
  margin-top: 3rem;
  font-weight: normal;
  line-height: 2.1rem;
  font-size: 1.4rem;
  color: #515255; }
  @media (max-width: 768px) {
    .c-product__text {
      text-align: center; } }

.c-product__list {
  font-weight: normal;
  line-height: 3.5rem;
  font-size: 1.2rem;
  color: #515255;
  margin-top: 2rem; }

.c-product__wrapper-btn {
  display: flex;
  align-items: center;
  margin-top: 2rem; }

.c-product__btn {
  background: #016098;
  font-weight: 500;
  line-height: 1.7rem;
  font-size: 1.3rem;
  text-align: center; }

.c-cart-product {
  margin-top: -5rem; }
  .c-cart-product__item {
    padding: 0 3.5rem 4rem 3.5rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 16px rgba(0, 0, 0, 0.16);
    margin-top: 15rem; }
  .c-cart-product__title {
    font-weight: 500;
    line-height: 2.4rem;
    font-size: 1.8rem;
    text-align: center;
    color: #515255; }
  .c-cart-product__wrap-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 20rem; }
    @media (max-width: 768px) {
      .c-cart-product__wrap-img {
        height: 27rem; } }
  .c-cart-product__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 28rem;
    padding-top: 4rem; }
  .c-cart-product__img {
    position: absolute;
    top: -70px; }
  .c-cart-product__list {
    font-weight: normal;
    line-height: 2rem;
    font-size: 1.4rem;
    color: #949699;
    list-style: none;
    padding-left: 3rem; }
    .c-cart-product__list.checked--red {
      position: relative; }
      .c-cart-product__list.checked--red li::before {
        content: '';
        position: absolute;
        left: 0;
        background-image: url("/img/list--red.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 2.5rem;
        height: 2rem; }
  .c-cart-product__list-item:not(:first-child) {
    margin-top: 1rem; }
  .c-cart-product__btn {
    background: #016098; }

.c-today__text {
  font-weight: normal;
  line-height: 2.3rem;
  font-size: 1.6rem;
  color: #FFFFFF; }

.c-today__list {
  font-weight: normal;
  line-height: 2rem;
  font-size: 1.4rem;
  list-style: none;
  padding-left: 3rem;
  margin: 2rem 0 3.5rem 0; }
  .c-today__list.checked--red {
    position: relative; }
    .c-today__list.checked--red li::before {
      content: '';
      position: absolute;
      left: 0;
      background-image: url("/img/list--red.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 2.5rem;
      height: 2rem; }

.c-today__list-item:not(:first-child) {
  margin-top: 1rem; }

.mobile-arrow {
  position: relative;
  margin: 2rem 0 3rem 0; }

.btn-scroll {
  perspective: 400px;
  position: fixed;
  right: 19%;
  bottom: 200px;
  width: 8rem;
  height: 8rem;
  opacity: 0; }
  .btn-scroll__link {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: absolute;
    transition: opacity, transform 0.3s;
    transform-style: preserve-3d; }
    .btn-scroll__link::before {
      content: '';
      position: absolute;
      top: 35px;
      width: 3rem;
      height: 3rem;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: rotate(135deg);
      transition: transform 0.3s; }
    .btn-scroll__link:hover {
      opacity: 1;
      transform: rotateX(30deg); }

.c-turtle-wrap {
  overflow: hidden; }

.c-turtle__img {
  position: relative;
  top: 95px; }

.section-promo {
  position: relative;
  padding-bottom: 4rem;
  color: #333;
  text-align: center; }
  @media (max-width: 768px) {
    .section-promo {
      background-image: url("/img/bg_blur.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%; } }
  @media (min-width: 768px) {
    .section-promo {
      text-align: left; } }
  .section-promo__content {
    min-height: 36rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    @media (max-width: 768px) {
      .section-promo__content {
        min-height: 65rem;
        padding-top: 3rem; } }
    @media (max-width: 576px) {
      .section-promo__content {
        min-height: calc(100vh - 20rem); } }
  .section-promo__desc {
    font-weight: 900;
    line-height: 4.5rem;
    font-size: 4rem;
    letter-spacing: 0.03em;
    text-transform: uppercase; }
  .section-promo__bottom-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    line-height: normal;
    font-size: 2.2rem;
    color: #F2F3F5;
    margin-top: 2rem;
    position: relative; }
    .section-promo__bottom-text::before {
      content: '';
      position: absolute;
      background-image: url("/img/section-promo-bottom.svg");
      background-repeat: no-repeat;
      top: -70px;
      width: 33px;
      height: 50px; }
    @media (max-width: 768px) {
      .section-promo__bottom-text {
        margin-top: 12rem; } }
  .section-promo__subtitle {
    font-weight: 300;
    line-height: 2.8rem;
    font-size: 2rem; }
  .section-promo__title {
    font-weight: 300;
    line-height: normal;
    font-size: 2.2rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #F2F3F5; }
    .section-promo__title.\--line {
      position: relative; }
      .section-promo__title.\--line::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 2px;
        background-color: #F4B000;
        left: -1.5rem; }
  .section-promo__custom-text {
    font-weight: bold;
    line-height: 3.7rem;
    font-size: 2.6rem; }
  .section-promo__title-bottom {
    font-weight: bold;
    line-height: normal;
    font-size: 3.2rem;
    margin-top: 6rem; }
  .section-promo__list {
    list-style: none;
    padding-left: 1rem;
    font-weight: normal;
    line-height: 2.3rem;
    font-size: 1.6rem; }
    .section-promo__list-item {
      position: relative; }
      .section-promo__list-item::before {
        content: '-';
        position: absolute;
        left: -1rem; }
      .section-promo__list-item:not(:first-child) {
        margin-top: 1rem; }
  .section-promo--dot::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5) url("/img/dot.svg") 0 0 repeat; }

.section-promo-bottom {
  padding: 6rem 0 0 0; }
  .section-promo-bottom__content {
    min-height: 36rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5rem 0; }
    @media (max-width: 768px) {
      .section-promo-bottom__content {
        padding: 0 0 5rem 0; } }
  .section-promo-bottom__desc {
    font-weight: normal;
    line-height: normal;
    font-size: 2.2rem;
    color: #626366;
    text-align: left;
    margin: 3rem 0; }
  .section-promo-bottom__title {
    font-weight: 900;
    line-height: 5.6rem;
    font-size: 4rem;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #fff;
    text-align: left; }

.section-footer__text {
  font-weight: normal;
  line-height: 2.1rem;
  font-size: 1.2rem;
  color: #fff; }

.section-footer-bg {
  position: relative; }
  .section-footer-bg::before {
    content: '';
    position: absolute;
    background-image: url("/img/yellow-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    width: 100%;
    height: 11rem; }

.section-footer__wrap-logo {
  display: flex;
  align-items: center; }

.section-footer__wrap-text {
  font-weight: normal;
  line-height: normal;
  font-size: 1.2rem;
  color: #ffffff; }
  .section-footer__wrap-text--bold {
    font-weight: bold;
    line-height: 2.1rem;
    text-transform: uppercase; }

.video-fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  width: auto;
  min-height: 100%;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-size: cover; }

.c-input-check__list {
  list-style: none; }

.c-input-check__inner {
  margin-bottom: 1.5rem;
  font-weight: normal;
  line-height: 2.5rem;
  font-size: 1.6rem; }

.c-input-check input[type="checkbox"] {
  display: none; }

.c-input-check input[type="radio"] {
  display: none; }

.c-input-check__label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #626366; }
  .c-input-check__label:hover {
    color: black; }
  .c-input-check__label.\--radio::before {
    content: '';
    position: absolute;
    left: -2rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    border: 1px solid #BCBFC4; }
  .c-input-check__label.\--radio::after {
    content: '';
    position: absolute;
    left: -1.6rem;
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 50%;
    background-color: red; }
    .c-input-check__label.\--radio::after:hover::after {
      transform: scale(2); }
  .c-input-check__label.\--checked::before {
    content: '';
    position: absolute;
    left: -2rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 2px;
    border: 1px solid #BCBFC4; }
  .c-input-check__label.\--checked::after {
    content: '';
    position: absolute;
    left: -1.6rem;
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 50%;
    background-color: red; }

.tm1-nav__item.\--active {
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: #FAFAFA;
  overflow: hidden; }

.tm1-nav__link {
  font-weight: normal;
  line-height: 2.5rem;
  font-size: 1.6rem;
  color: #626366;
  border-bottom: 0.25px solid #016098;
  text-decoration: none;
  padding-bottom: 0.5rem;
  margin: 0 2rem; }
  .tm1-nav__link:hover, .tm1-nav__link:focus, .tm1-nav__link:active {
    text-decoration: none;
    color: #2E3033; }
  .tm1-nav__link:hover {
    border-color: #016098; }

.tm2-nav__item {
  padding: 1.5rem 1.8rem; }
  .tm2-nav__item:hover {
    box-shadow: 0 0 0 1px #F2F3F5; }
  .tm2-nav__item.\--active {
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.15);
    background-color: #FAFAFA; }

.tm2-nav__wrap-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11rem; }

.tm2-nav__img {
  max-width: 10rem; }

.tm2-nav__title {
  font-weight: bold;
  line-height: 2.3rem;
  font-size: 1.6rem;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #626366;
  margin-top: 1rem; }

.c-main {
  margin-top: -5rem; }
  .c-main__item {
    margin-top: 5rem;
    font-size: 1.4rem;
    text-align: center;
    max-width: 35rem;
    margin-right: auto;
    margin-left: auto;
    position: relative; }
  .c-main__wrap-img {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .c-main__wrap-img.\--xs {
      height: 4rem; }
    .c-main__wrap-img.\--sm {
      height: 7rem; }
    .c-main__wrap-img.\--md {
      height: 100%; }
    .c-main__wrap-img.\--xl {
      height: 12rem; }
  .c-main__img {
    max-height: 10rem; }
    .c-main__img.\--xs {
      max-height: 4rem;
      max-width: 4rem; }
    .c-main__img.\--sm {
      max-width: 7rem;
      max-height: 7rem; }
    .c-main__img.\--md {
      max-width: 8rem;
      max-height: 8rem; }
    .c-main__img.\--xl {
      max-width: 20rem;
      max-height: 20rem; }
  .c-main__title {
    font-weight: normal;
    line-height: normal;
    text-align: center; }
    .c-main__title.\--standart {
      letter-spacing: 0.02em;
      text-transform: uppercase; }
    .c-main__title.\--xs {
      font-size: 1.2rem;
      font-weight: normal;
      line-height: 1.5rem; }
    .c-main__title.\--sm {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.8rem; }
    .c-main__title.\--md {
      font-size: 1.6rem;
      letter-spacing: 0.02em;
      line-height: 1.8rem;
      text-transform: uppercase; }
    .c-main__title.\--xl {
      font-weight: normal;
      line-height: 3.2rem;
      font-size: 2.3rem; }
  .c-main__text {
    font-weight: normal;
    line-height: 1.8rem;
    margin-top: 1rem; }
    .c-main__text.\--xl {
      line-height: 2.5rem;
      font-size: 1.6rem; }
    .c-main__text.\--md {
      font-weight: normal;
      line-height: 1.5rem;
      font-size: 1.2rem;
      text-align: center; }

.c-number__item {
  text-align: left;
  max-width: 24rem; }
  .c-number__item.\--bg {
    padding: 3.5rem 3rem 4.5rem 3.5rem;
    background-color: #2E3033; }

.c-number__title {
  font-weight: bold;
  line-height: normal;
  font-size: 4rem; }
  @media (max-width: 768px) {
    .c-number__title {
      font-size: 3rem; } }

.c-number__text {
  font-weight: normal;
  line-height: 1.8rem;
  font-size: 1.4rem;
  color: #BCBFC4; }
  .c-number__text.\--gray {
    color: #BCBFC4; }

.c-number__small {
  font-size: 2.5rem; }
  @media (max-width: 768px) {
    .c-number__small {
      font-size: 2rem; } }

.tm1-cart__wrap-img {
  display: flex;
  align-items: center;
  justify-content: center; }
  .tm1-cart__wrap-img.\--sm {
    height: 12rem; }
  .tm1-cart__wrap-img.\--standart {
    height: 16rem; }

.tm1-cart__img.\--sm {
  max-width: 12rem; }

.tm1-cart__img.\--standart {
  max-width: 18rem; }

.tm1-cart__title {
  text-align: center;
  font-weight: normal;
  color: #626366;
  margin-top: 1rem; }
  .tm1-cart__title.\--sm {
    line-height: 1.5rem;
    font-size: 1.2rem; }
  .tm1-cart__title.\--standart {
    line-height: 2.5rem;
    font-size: 1.6rem; }

.tm2-cart__item {
  background-color: #F2F3F5;
  padding: 2rem 2rem 3rem 2rem; }

.tm2-cart__wrap-img {
  text-align: center; }

.tm2-cart__img {
  max-width: 35rem; }

.tm2-cart__title {
  text-align: center;
  font-weight: bold;
  line-height: 2.6rem;
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-top: 3rem;
  color: #626366; }

.inline {
  display: flex;
  justify-content: center;
  align-items: center; }
  .inline__title {
    font-weight: 900;
    line-height: normal;
    font-size: 1.4rem;
    letter-spacing: 0.05em;
    color: #4F4F4F; }
  .inline__wrap-img {
    margin-right: 1rem; }
  .inline__img {
    max-width: 3.5rem; }

.c-tech {
  min-width: 100%;
  flex: 100;
  font-size: 1.6rem; }
  .c-tech__item {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem; }
  .c-tech__value {
    color: #16293a;
    font-weight: normal; }
  .c-tech__line {
    flex: 1;
    border-bottom: 2px dotted #BCBFC4;
    margin: 0 1rem;
    margin-bottom: 0.5rem; }

.c-review__item {
  display: flex;
  padding: 1rem; }
  @media (max-width: 768px) {
    .c-review__item {
      flex-direction: column; } }

.c-review__inner.\--main {
  margin-right: 9rem;
  flex: 50%;
  text-align: center;
  color: #626366; }
  @media (max-width: 768px) {
    .c-review__inner.\--main {
      margin: 0 0 2rem 0; } }

.c-review__wrap-img {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 15rem;
  margin: auto;
  box-shadow: 0 0 0 1px #E1E2E6; }

.c-review__img {
  max-width: 15rem; }

.c-review__title {
  font-weight: bold;
  line-height: 2.5rem;
  margin-top: 3rem; }

.c-review__desc {
  font-weight: normal;
  line-height: 1.6rem;
  font-size: 1rem;
  margin-top: 1rem; }

.c-review__text {
  font-style: italic;
  font-weight: normal;
  line-height: 2.5rem;
  font-size: 1.6rem;
  color: #626366; }

.c-review__date {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: normal;
  line-height: 2.1rem;
  font-size: 1.4rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #949699;
  position: relative; }
  .c-review__date-img {
    margin-right: 1rem; }

.c-modal {
  position: relative;
  z-index: 1000000; }
  .c-modal__container {
    position: fixed;
    backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: 0;
    background: #0000005a;
    top: 0;
    touch-action: manipulation;
    transform: translateZ(0);
    width: 100%; }
  .c-modal__content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 55rem;
    height: 100%;
    margin: auto;
    padding: 0 2rem; }

.c-advance {
  margin-top: -5rem; }
  .c-advance:first-of-type {
    margin-top: 0; }
  .c-advance__item {
    font-size: 1.6rem;
    line-height: normal;
    margin-top: 5rem; }
    @media (max-width: 768px) {
      .c-advance__item {
        position: relative;
        margin-left: 5rem; }
        .c-advance__item::before {
          content: '';
          position: absolute;
          background-image: url("/img/green_arrow.svg");
          background-repeat: no-repeat;
          transform: rotate(90deg);
          height: 2rem;
          width: 15rem;
          left: -10rem;
          top: 14rem; } }
    .c-advance__item.\--last::before {
      content: none; }
  .c-advance__wrap-img {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
    position: relative; }
    .c-advance__wrap-img.\--down::before {
      content: '';
      position: absolute;
      background-image: url("/img/arrow_down.png");
      background-repeat: no-repeat;
      width: 8rem;
      height: 2rem;
      right: -6rem; }
      @media (max-width: 768px) {
        .c-advance__wrap-img.\--down::before {
          content: none; } }
    .c-advance__wrap-img.\--up::before {
      content: '';
      position: absolute;
      background-image: url("/img/arrow_up.png");
      background-repeat: no-repeat;
      width: 8rem;
      height: 2rem;
      right: -6rem; }
      @media (max-width: 768px) {
        .c-advance__wrap-img.\--up::before {
          content: none; } }
  .c-advance__wrap-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7.5rem; }
  .c-advance__title {
    font-weight: bold;
    letter-spacing: 0.03em;
    color: #515255;
    margin-top: 2rem; }
  .c-advance__text {
    font-weight: normal;
    margin-top: 1rem;
    color: #626366;
    text-align: center;
    font-weight: normal;
    line-height: 2.5rem;
    font-size: 1.6rem;
    text-align: center; }

.c-cart {
  margin-top: -4rem; }
  .c-cart__item {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 4rem);
    margin-top: 4rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 16px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 3rem 2rem 3.5rem 3rem;
    border-radius: 2px;
    transition: 0.3s all; }
    .c-cart__item.\--border-bottom {
      border-bottom: 4px #949699 solid; }
      .c-cart__item.\--border-bottom:hover {
        border-bottom: 4px #FF3D33 solid; }
    .c-cart__item.\--border-top {
      border-top: 4px #949699 solid; }
      .c-cart__item.\--border-top:hover {
        border-top: 4px #FF3D33 solid; }
  .c-cart__inner:not(:first-child) {
    margin-top: 3rem; }
  .c-cart__btn {
    display: flex;
    justify-content: center;
    align-items: center; }
